code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,900&display=swap');
  :root {
      --color-primary: #7380ec;
      --color-danger: #ff7782;
      --color-success: #41f1b6;
      --color-base: #40aa22;
      
      --color-warning: #ffbb55;
      --color-white: #fff;
      --color-info-dark: #7d8da1;
      --color-info-light: #dce1eb;
      --color-dark: #363949;
      --color-light: rgba(132, 139, 200, 0.18);
      --color-primary-variant: #111e88;
      --color-dark-variant: #677483;
      --color-background: #f6f6f6;
      --card-border-radius: 1.0rem;
      --border-radius-1: 0.4rem;
      --border-radius-2: 0.8rem;
      --bordar-radius-3: 1.2rem;
      --card-padding: 1.8rem;
      --padding-1: 1.2rem;
      --box-shadow: 0 2rem 3rem var(--color-light);
  } 


  
  * {
      margin: 0%;
      padding: 0%;
      outline: 0;
      appearance: none;
      border: 0;
      text-decoration: none;
      list-style: none;
      box-sizing: border-box;
  }
  
  html {
      font-size: 14px;
  }
  
  body {
      background: var(--color-background);
      font-family: poppins, sans-serif;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
  #root{
    /* width: 100vw; */
    /* height: 100vh; */
    font-size: 0.88rem;
    background: var(--color-background);
    user-select: none;
    overflow-x: hidden;
    color: var(--color-dark);
    margin: 0;
  }
  
  .login-container {
    display: flex; /* Enable flexbox layout for centering */
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    margin: auto;
  }
  
  .login-container form{
    display: flex;
    flex-direction: column;
    /* width: 70%; */
    gap: 10px;
    width: 25%;
    background-color: var(--color-info-light); /* Optional background color */
    padding: 20px; /* Optional padding */
    border-radius: 5px; /* Optional rounded corners */
    gap: 5px;
    border-radius: 10px;
  }
  .login-field{
    display: grid;
    grid-template-columns: 15% auto;
    gap:20px;
    height: 25px;
    margin-top: 20px;
    font-size: 15px;
    align-items: center;
  }
  
  .login-field input{
      height: 2.5rem;
      padding: 0.5rem;
      font-size: 1.3rem;
  }
  
  .login-field input:focus {
      outline: solid var(--color-dark-variant) 1px;
  }
  
  
  .login-logo{
      width: 200px;
      margin-top: -150px;
  }
  
  form button{
      width: 80%;
      height: 35px;
      background: var(--color-dark-variant);
      margin: auto;
      margin-top: 2rem;
      font-size: 1rem;
      color: var(--color-info-light);
  }
  
  form button:hover{
      background-color: var(--color-dark);
  }
  .login-container footer{
      display: flex;
      position: absolute;
      bottom: 20px;
      align-items: center;
      justify-content: center;
      /* height: 20px; */
  }
  
  .login-container footer img{
      width: 50px;
      border-radius: 50%;
  }
  
  .profile-circle {
      width: 40px;
      height: 40px;
      /* border-radius: var(--border-radius-1); */
      background-color: var(--color-dark-variant); /* Replace with your desired background color */
      color: var(--color-info-light);
      font-size: 1.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  
  .container {
      display: grid;
      width: 83%;
      margin: 0 auto;
      gap: 1.5rem;
      position: absolute;
      top: 6rem;
      left: 17rem;
      grid-template-columns: auto 25rem;
  }
  
  .containerform {
      display: grid;
      width: 80%;
      margin: 0 auto;
      gap: 1rem;
      position: absolute;
      top: 6rem;
      left: 17rem;
      grid-template-columns: auto 20rem;
  }
  .containerfull {
      display: grid;
      width: 80%;
      margin: 0 auto;
      gap: 1rem;
      position: absolute;
      top: 6rem;
      left: 17rem;
      grid-template-columns: auto;
  }
  
  /* to show user and theme control on top */
  .dashuser{
    display: block;
    /* position: absolute; */
    top: 1.5rem;
    right: 1rem;
    z-index: 1000;
    position: fixed;
    border-bottom: solid 1px var(--color-base);
    border-top: solid 1px var(--color-base);    
    backdrop-filter: blur(4px);
    width: 85%;
    
  }
  
  a {
      color: var(--color-dark);
  }
  
  img {
      display: block;
      width: 100%;
  }
  
  h1 {
      font-weight: 800;
      font-size: 1.8rem;
  }
  
  h2 {
      font-size: 1.4rem;
  }
  
  h3 {
      font-size: 0.87rem;
  }
  
  h4 {
      font-size: 0.87rem;
  }
  
  h5 {
      font-size: 0.77rem;
  }
  
  small {
      font-size: 0.75rem;
  }
  /* Margin Top */
  .mt-1 { margin-top: 1rem; }
  .mt-2 { margin-top: 2rem; }
  .mt-3 { margin-top: 3rem; }
  .mt-4 { margin-top: 4rem; }
  
  /* Margin Bottom */
  .mb-1 { margin-bottom: 1rem; }
  .mb-2 { margin-bottom: 2rem; }
  .mb-3 { margin-bottom: 3rem; }
  .mb-4 { margin-bottom: 4rem; }
  
  /* Margin Left */
  .ml-1 { margin-left: 1rem; }
  .ml-2 { margin-left: 2rem; }
  .ml-3 { margin-left: 3rem; }
  .ml-4 { margin-left: 4rem; }
  
  /* Margin Right */
  .mr-1 { margin-right: 1rem; }
  .mr-2 { margin-right: 2rem; }
  .mr-3 { margin-right: 3rem; }
  .mr-4 { margin-right: 4rem; }
  
  /* Margin X (left and right) */
  .mx-1 { margin-left: 1rem; margin-right: 1rem; }
  .mx-2 { margin-left: 2rem; margin-right: 2rem; }
  .mx-3 { margin-left: 3rem; margin-right: 3rem; }
  .mx-4 { margin-left: 4rem; margin-right: 4rem; }
  
  /* Margin Y (top and bottom) */
  .my-1 { margin-top: 1rem; margin-bottom: 1rem; }
  .my-2 { margin-top: 2rem; margin-bottom: 2rem; }
  .my-3 { margin-top: 3rem; margin-bottom: 3rem; }
  .my-4 { margin-top: 4rem; margin-bottom: 4rem; }
  
  /* Padding Top */
  .pt-1 { padding-top: 1rem; }
  .pt-2 { padding-top: 2rem; }
  .pt-3 { padding-top: 3rem; }
  .pt-4 { padding-top: 4rem; }
  
  /* Padding Bottom */
  .pb-1 { padding-bottom: 1rem; }
  .pb-2 { padding-bottom: 2rem; }
  .pb-3 { padding-bottom: 3rem; }
  .pb-4 { padding-bottom: 4rem; }
  
  /* Padding Left */
  .pl-1 { padding-left: 1rem; }
  .pl-2 { padding-left: 2rem; }
  .pl-3 { padding-left: 3rem; }
  .pl-4 { padding-left: 4rem; }
  
  /* Padding Right */
  .pr-1 { padding-right: 1rem; }
  .pr-2 { padding-right: 2rem; }
  .pr-3 { padding-right: 3rem; }
  .pr-4 { padding-right: 4rem; }
  
  /* Padding X (left and right) */
  .px-1 { padding-left: 1rem; padding-right: 1rem; }
  .px-2 { padding-left: 2rem; padding-right: 2rem; }
  .px-3 { padding-left: 3rem; padding-right: 3rem; }
  .px-4 { padding-left: 4rem; padding-right: 4rem; }
  
  /* Padding Y (top and bottom) */
  .py-1 { padding-left: 1rem; padding-right: 1rem; }
  .py-2 { padding-left: 2rem; padding-right: 2rem; }
  .py-3 { padding-left: 3rem; padding-right: 3rem; }
  .py-4 { padding-left: 4rem; padding-right: 4rem; }
  
  
  .mb-1{
      margin-bottom: 1rem;
  }
  
  .mb-2{
      margin-bottom: 2rem;
  }
  .mb-3{
      margin-bottom: 3rem;
  }
  .mb-4{
      margin-bottom: 4rem;
  }
  
  
  .mx-1{
      margin: 0 1rem;
  }
  
  .mx-2{
      margin: 0 2rem;
  }
  .mx-3{
      margin: 0 3rem;
  }
  .mx-4{
      margin: 0 4rem;
  }
  
  .my-1{
      margin: 1rem 0;
  }
  
  .my-2{
      margin: 2rem 0;
  }
  .my-3{
      margin: 3rem 0;
  }
  .my-4{
      margin: 4rem 0;
  }
  
  
  .profile-photo {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 50%;
      overflow: hidden;
  }
  
  .profileDash{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 1rem;
  }
  /* ================top bar user icon and close button ============ */
  
  .topnav {
    /* display: flex; */
    /* justify-content: space-between; */
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 0.5rem;
  }
  
  .text-muted {
      color: var(--color-info-dark);
  }
  
  p {
      color: var(--color-dark-variant);
  }
  
  b {
      color: var(--color-dark);
  }
  
  .primary {
      color: var(--color-primary);
  }
  
  /* status of any thing background  */
  .bgdanger {
      background: var(--color-danger);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-white);
      border-radius: 10px;
  }
  
  .bgsuccess {
      background: var(--color-success);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-white);
      border-radius: 10px;
  
  }
  
  .bgwarning {
      background: var(--color-warning);
      border-radius: 10px;
      color: white;
      padding: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-white);
      border-radius: 10px;
  
  }
  
  .radio-button {
      width: 2rem;
      /* height: 20px; */
      border-radius: 50%;
      border: 2px solid var(--color-primary);
      padding: 2px;
      cursor: pointer;
      position: relative;
    }
    
    .radio-button::after {
      content: "";
      position: absolute;
      display: none;
      left: 0.45rem;
      top: 0.45rem;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: var(--color-dark);
    }
    
    .radio-button:hover {
      background-color: var(--color-base);
    }
    
    .radio-button:checked {
      background-color: var(--color-base);
    }
    
    .radio-button:checked::after {
      display: block;
    }
    
  
  .asidecontainer{
    width: 13rem;
    margin-left: 1rem;
    height: 100vh;
    display: block;
    z-index: 100;
    overflow: hidden;
    /* position: relative; */
    position: fixed;
    
  }
  
  /* .asidecontainer{
    display: grid;
    width: 1rem;
    margin-left: 0.5rem;
    gap: 1.8rem; 
  }
    */
  
  aside {
      height: max-content;
      box-sizing: border-box;
      scroll-behavior: smooth;
      /* background-color: #e5f0e0; */
      /* background-color: #000000; */
  }
  
  aside .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* background-color: var(--color-white); */
      padding: 1rem;
      /* border-bottom: solid 1px #40aa22; */
  }
  
  aside .logo {
      display: flex;
      gap: 0.8rem;
      color: #40aa22;
      align-items: center;
      text-align: center;
      margin: auto;
  }
  
  aside .logo img {
      width: 8rem;
      /* height: 5rem; */
  }
  
  aside .close {
      display: none;
  }
  
  
  /*============side bar=========*/
  
  aside .sidebar {
      display: flex;
      flex-direction: column;
      height: 86vh;
      position: relative;
      transition: all 0.2s ease-in-out;
      overflow: scroll;
  }
  .sidebar::-webkit-scrollbar {
      display: none;
    }
  
  aside .sidebar a {
      display: flex;
      gap: 1rem;
      align-items: center;
      position: relative;
      margin-left: 1.5rem;
      color: var(--color-info-dark);
      height: 3.5rem;
      transition: all 300ms ease;
  }
  
  aside .sidebar a span {
      font-size: 1.5rem;
      transition: all 300ms ease;
  }
  
  
  aside .sidebar .menu:last-child a{
      margin-top: 1rem;
      border-top: var(--color-danger) 1px solid;
      color: #ff7782;
  }
  
  aside .sidebar .active {
      background: var(--color-light);
      color: var(--color-primary);
      margin-left: 0;
      transition: all 0.2s ease-in-out;
  }
  
  aside .sidebar .active a:before{
      content: "";
      width: 6px;
      position: absolute;
      left: -22px;
      height: 100%;
      background-color: var(--color-primary);
  }
  
  aside .sidebar a.active span {
      color: var(--color-primary);
      margin-left: calc(1rem - 3px);
  }
  
  aside .sidebar a:hover {
      color: var(--color-primary);
  }
  
  aside .sidebar a:hover span {
      margin-left: 1rem;
  }
  
  
  /* additional*/
  
  aside .sidebar .menu:last-child:hover {
      color: red;
  }
  
  
  /*==============main section*============*/
  
  main {
      margin-top: 0.2rem;
  }
  
  main .date {
      display: inline-block;
      background: var(--color-light);
      border-radius: var(--border-radius-1);
      margin-top: 1rem;
      padding: 0.5rem 1.6rem;
  }
  
  main .date input[type="date"] {
      background: transparent;
      color: var(--color-dark);
  }
  
  main .insights {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1.6rem;
  }
  
  main .insights>div {
      background: var(--color-white);
      padding: var(--card-padding);
      border-radius: var(--card-border-radius);
      margin-top: 0.2rem;
      box-shadow: var(--box-shadow);
      transition: all 300ms ease;
  }
  
  main .insights>div:hover {
      box-shadow: none;
  }
  
  main .insights>div span {
      background: var(--color-primary);
      padding: 0.5rem;
      border-radius: 50%;
      color: var(--color-white);
      font-size: 2rem;
  }
  
  main .insights>.room.maintenance span {
      background: var(--color-danger);
  }
  
  main .insights>.room.available span {
      background: var(--color-success);
  }
  
  main .insights>div.expenses span {
      background: var(--color-danger);
  }
  
  main .insights>div.income span {
      background: var(--color-success);
  }
  
  main .insights>div .middle {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  main .insights h3 {
      margin: 1rem 0 0.6rem;
      font-size: 1rem;
  }
  
  main .insights .progress {
      position: relative;
      width: 92px;
      height: 92px;
      border-radius: 50%;
  }
  
  main .insights svg {
      width: 7rem;
      height: 7rem;
  }
  
  main .insights svg circle {
      fill: none;
      stroke: var(--color-primary);
      stroke-width: 10px;
      /* stroke-linecap: round; */
      transform: translate(17px, 17px);
      /* stroke-dashoffset: 25; */
      stroke-dasharray: 222;
      transform-origin: center;
      animation: progress 2s linear forwards;
  }
  
  .bgCircle{
      border: 1px solid var(--color-warning);
      width: 55px;
      border-radius: 50%;
      height: 55px;
      position: absolute;
      left: 30%;
      top: 30%;
  }
  
  main .sales .actionList .actions{
      display: flex;
      flex-direction: column;
      justify-content: space-between;;
  }
  .sales .d-flex{
      padding: 0 0 0.5rem 0;
      border-bottom: 1px solid var(--color-info-light);
  }
  
  .d-flex{
      display: flex;
      justify-content: space-between;
  }
  
  .action-link {
      padding: 0.2rem 0;
      margin: 0.1rem;
      border-bottom: var(--color-dark) solid 1px;
      cursor: pointer;
  }
  .action-link:hover{
      border-bottom: var(--color-base) solid 1px;
      color: var(--color-base);
  }
  
  .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25rem;
      width: 100vw;
  }
  
  @keyframes progress {
      from {
          stroke-dasharray: 0; /* Adjust this value to start from 12 o'clock position */
      }
      to {
          stroke-dasharray: 222; /* Adjust this value to complete the progress */
      }
    }
  
  main .insights .sales svg circle {
      /* stroke-dashoffset: 30; */
      stroke-dasharray: 222;
  }
  
  main .insights .income svg circle {
      /* stroke-dashoffset: 0; */
      stroke-dasharray: 222;
  }
  
  main .insights .progress .number {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
  }
  
  
  /* table orders*/
  /* table for debit and Credit */
  main .credit{
      margin-top: 2rem;
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
  }
  
  main .credit h2 {
      margin-bottom: 0.8rem;
      text-align: center;
  }
  
  main .credit table {
      background: var(--color-white);
      width: 100%;
      border-radius: var(--card-border-radius);
      padding: var(--card-padding);
      text-align: center;
      box-shadow: var(--box-shadow);
      transition: all 300ms ease;
  }
  
  main .credit table:hover{
      box-shadow: none;
  }
  
  main .debit-table a{
      display: block;
      margin-top: 10px;
      text-align: center;
  }
  main .debit-table a:hover{
      color: var(--color-danger);
  }
  
  /* end of credit */
  
  .max-col{
      align-items: center;
      color: var(--color-danger);
      font-size: 1.1rem;
      padding-top: 0.8rem;
  }
  
  
  .tablePagination{
      padding: 0.5rem;
      border-radius: 0.25rem;
      outline: solid 0.02rem var(--color-dark);
      background-color: var(--color-background);
      color: var(--color-dark);
  }
  
  .tablePagination-no{
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .tablePagination-btn{
      padding: 0.4rem;
      outline: solid 1px var(--color-dark-variant);
      margin: 0.8rem 0.3rem;
  }
  
  .tablePagination-btn:hover{
      background-color: var(--color-dark-variant);
      color: var(--color-info-light);
  }
  .tablePagination-btn.active{
      background-color: var(--color-success);
  }
  
  .table-search{
      padding: 0 1rem;
  }
  
  .table-search input{
      width: 30%;
      padding: 0.3rem;
      outline: solid 1px var(--color-dark-variant);
      border-radius: var(--border-radius-1);
      background-color: var(--color-background);
      color: var(--color-primary);
  }
  
  
  .cell_center{
      height: auto;
  /*     
      width: 200%;
      margin: auto; */
  }
  /* table cell center */
  
  main .recent-orders {
      margin-top: 2rem;
  }
  
  main .recent-orders h2 {
      margin-bottom: 0.8rem;
  }
  
  main .recent-orders table {
      background: var(--color-white);
      width: 100%;
      border-radius: var(--card-border-radius);
      padding: var(--card-padding);
      text-align: center;
      box-shadow: var(--box-shadow);
      transition: all 300ms ease;
  }
  
  main .recent-orders table:hover {
      box-shadow: none;
  }
  
  main table tbody td {
      height: 2.8rem;
      border-bottom: 1px solid var(--color-light);
      color: var(--color-dark-variant);
  }
  
  main table tbody tr:last-child td {
      border: none;
  }
  
  main .recent-orders a {
      margin-top: 0.5rem;
      display: block;
      text-align: center;
  }
  
  main .recent-orders a:hover {
      color: var(--color-danger);
  }
  
  .right .top {
      display: flex;
      justify-content: end;
      gap: 2rem;
      margin: 0.5rem;
  }
  
  .right .top button {
      display: none;
  }
  
  .right .theme-toggler {
      background: var(--color-light);
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1.6rem;
      width: 4.2rem;
      cursor: pointer;
      border-radius: var(--border-radius-1);
  }
  
  .right .theme-toggler span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 50%;
      font-size: 1rem;
  }
  
  .right .theme-toggler span.active {
      color: white;
      background: #40aa22;
      border-radius: var(--border-radius-1);
  }
  
  .right .top .profile {
      display: flex;
      gap: 1.2rem;
      text-align: right;
  }
  
  
  /*========== recent updates ========*/
  
  .right .recent-updates {
      margin-top: 1rem;
  }
  
  .right .recent-updates h2 {
      margin-bottom: 0.8rem;
  }
  
  .right .recent-updates .updates {
      background: var(--color-white);
      padding: var(--card-padding);
      border-radius: var(--card-border-radius);
      box-shadow: var(--box-shadow);
      transition: all 300ms ease;
  }
  
  .right .recent-updates .updates:hover {
      box-shadow: none;
  }
  
  .right .recent-updates .updates .update {
      display: grid;
      grid-template-columns: 2.6rem auto;
      gap: 1rem;
      margin-top: 1rem;
  }
  
  
  /*========== Analytics ========*/
  
  .sales-analytics {
      margin-top: 1.2rem;
  }
  
  .sales-analytics .item {
      background: var(--color-white);
      margin-top: 0.5rem;
      padding: 1rem;
      display: flex;
      gap: 0.8rem;
      width: auto;
      height: 5.2rem;
      align-items: center;
      justify-content: left;
      border-radius: var(--card-border-radius);
      box-shadow: var(--box-shadow);
      transition: all 300ms ease;
  }
  
  .sales-analytics .item:hover {
      box-shadow: none;
  }
  
  .sales-analytics .item .right {
      display: flex;
      align-items: center;
      margin: auto;
      width: 100%;
      justify-content: space-between;
  }
  
  .sales-analytics .item .right h2 {
      font-size: 2.5rem;
  }
  
  .sales-analytics .item .icon {
      background: var(--color-success);
      color: var(--color-white);
      border-radius: 50%;
      padding: 0.4rem;
      align-self: center;
      display: flex;
  }
  
  .sales-analytics .item .icon-warn {
      background: var(--color-danger);
      color: var(--color-white);
      border-radius: 50%;
      padding: 0.4rem;
      align-self: center;
      display: flex;
  }
  
  /* =====theme-toggler===== */
  *{
      transition: all 0.2s ease-in-out;
  }
  .blue-theme-variables {
      --color-background: #021b4c;
      --color-white: #020824;
      --color-dark: #fcd701;
      --color-dark-variant: #3d34c0;
      --color-light: rgba(0, 12, 34, 0.4);
      --box-shadow: 0 2rem 3rem var(--color-light);
  }
  
  
  .dark-theme-variables {
      --color-primary: #b1afaf;
      --color-background: #181a1e;
      --color-white: #202528;
      --color-dark: #edeffd;
      --color-dark-variant: #a3bdcc;
      --color-info-light: #363949;
      --color-light: rgba(0, 0, 0, 0.4);
      --box-shadow: 0 2rem 3rem var(--color-light);
    }
  
   
    
    .dark-theme-variables.scheme-orange {
      --color-background: #222831;
      --color-white: #393e46;
      --color-dark: #ffb677;
      --color-dark-variant: #ff793f;
      --color-light: rgba(255, 255, 255, 0.4);
      --box-shadow: 0 2rem 3rem var(--color-light);
      --color-primary: #fa6304;
    }
    
    .dark-theme-variables.scheme-green {
      --color-background: #1a1a1a;
      --color-white: #2d2d2d;
      --color-dark: #ff847c;
      --color-dark-variant: #ff5733;
      --color-light: rgba(0, 0, 0, 0.4);
      --box-shadow: 0 2rem 3rem var(--color-light);
      --color-primary: #6eb04d;
    }
    
    .dark-theme-variables.scheme-blue {
      --color-background: #e8e8ea;
      --color-white: #3c3c3c;
      --color-dark: #000cf0;
      --color-dark-variant: #005eff;
      --color-light: rgba(255, 255, 255, 0.4);
      --box-shadow: 0 2rem 3rem var(--color-light);
      --color-primary: #0388a6;
    }
    
    .dark-theme-variables.scheme-violet {
      --color-background: #121212;
      --color-white: #272727;
      --color-dark: #1db954;
      --color-dark-variant: #1ed760;
      --color-light: rgba(255, 255, 255, 0.4);
      --box-shadow: 0 2rem 3rem var(--color-light);
      --color-primary: #8e2de2;
    }
    
    
    
    
  
  /*=====sub-menu-toggler===== */
  
  
  /* .submenu .childMenu{
      display: block;
      margin: 1rem center;
      align-items: center;
      text-align: center;
  }
  .submenu .childMenu{
      display: none;
  }
   .submenu .childMenu:hover{
      display: block;
      max-height: 200px;
  }
  .submenu .childMenu ul li{
      margin-top: 0.3rem;
      background: var(--color-info-dark);
  
  }
  .submenu .childMenu ul li:hover{
      display: block;
  }
  
  
  .submenu :checked+.childMenu {
      display: block;
      max-height: 200px;
    } */
  
  /* ==========Extra Code========= */
  .submenu {
      position: relative;
      display: inline-block;
      transition: all 0.2s ease-in-out;
  }
  
  .childMenu {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
  }
  
  .childMenu a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
  }
  
  .childMenu a:hover {
      background-color: #ddd;
  }
  
  .submenu:hover .childMenu {
      display: block;
      position: relative;
      margin-left: 200px;
  }
  
  .submenu:hover .dropbtn {
      background-color: #3e8e41;
  }
  
  
  /* ================form table enteries=============== */
  
  
  /* .entry one{
      display: grid;
      grid-template-columns: auto auto;
    } */
  
  .form {
      border: solid 1px var(--color-info-dark);
      border-radius: var(--border-radius-2);
  }
  
  .form h2,
  h1 {
      text-align: center;
      margin: 5px;
  }
  
  main h1 {
      text-align: left;
      margin: 1rem;
  }
  
  .entry-form {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
  }
  
  .entry-form tr{
  
      display: flex;
      justify-content: space-between;
      border-bottom: var(--color-info-dark) 1px solid;
  }
  
  .entry-block {
      display: grid;
      grid-template-columns: auto;
      gap: 0.5rem;
  }
  
  /* Style for the table */
  .entry-block table {
      width: 110%;
      border-collapse: collapse;
      margin-top: 0.5rem;
      left: -5%;
      position: relative;
  }
  
  /* Style for table header */
  .entry-block th {
      background-color: var(--color-info-dark);
      color: white;
      padding: 0.3rem;
      text-align: left;
      /* border-bottom: 0.2rem solid #01579b; */
  }
  
  /* Style for table rows */
  .entry-block td {
      padding: 0.2rem;
      border-bottom: 0.1rem solid var(--color-background);
  }
  
  /* Alternate row background color */
  .entry-block tbody tr:nth-child(even) {
      background-color: var(--color-white);
  }
  
  /* Hover effect for rows */
  .entry-block tbody tr:hover {
      background-color: var(--color-success);
  }
  
  
  .entry-three {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 1rem;
  }
  
  .entry-one input {
      margin: 0.5em;
      padding: 0.2rem;
      gap: 2rem;
      height: 2rem;
      font-size: 1rem;
      color: #212121;
      border: solid 1px var(--color-dark-variant);
  }

  select{
    padding-left: 5px;
  }
  
  .entry-one select, .entry-one option{
      margin: 0.5em;
      padding: 0.2rem;
      gap: 2rem;
      height: 2rem;
      /* width: 16rem; */
      font-size: 1rem;
      color: #212121;
      border: solid 1px var(--color-dark-variant);
  }
  .entry-one textarea {
      margin: 0.5em;
      padding: 0.2rem;
      gap: 2rem;
      min-height:2rem;
      font-size: 1rem;
      color: #212121;
      outline: solid var(--color-dark-variant) 1px;
      /* border: solid 1px var(--color-dark-variant); */
  }
  
  .entry-block textarea {
      outline: solid var(--color-dark-variant) 1px;
  }
  
  .entry-one select {
      margin: 0.5em;
      gap: 2rem;
      height: 2rem;
      padding: 0.2rem;
      font-size: 1rem;
      color: #212121;
      border: solid 1px var(--color-dark-variant);
  }
  
  .entry-one {
      display: grid;
      grid-template-columns: 15% auto;
      align-items: center;
      padding: 0.5rem;
  }
  
  .entry-show {
      display: grid;
      grid-template-columns: 30% auto;
      align-items: center;
      padding: 0.5rem;
      gap: 0.8rem;
  }
  
  .entry-show h5 {
      text-align: left;
      padding: 0.4rem;
      font-size: 1rem;
      border-bottom: 1px solid var(--color-dark);
  }
  
  .entry-show p{
      padding: 0.3rem;
      border-bottom: 1px solid var(--color-dark);
      width: 90%;
  }
  .entry-customer{
      display: grid;
      grid-template-columns: 15% auto 15% auto;
      align-items: center;
      padding: 0.5rem;
      margin: auto;
  }
  
  .entry-four {
      display: grid;
      grid-template-columns: 20% auto 20% auto;
      align-items: center;
      padding: 0.5rem;
      margin: auto;
      text-align: center;
  }
  
  .entry-four p {
      border-bottom: 1px solid var(--color-dark-variant);
      margin-right: 1rem;
      height: 1.4rem;
      margin: 0.4rem 0.5rem;
      text-align: left;
  }
  
  .entry-four h4 {
      text-align: right;
      margin-right: 0.3rem;
      padding: 0.3rem;
      line-height: 1.2rem;
  }
  
  .entry-four input {
      margin: 0.5em;
      gap: 1rem;
      height: 1.8rem;
      padding: 0.2rem;
      font-size: 1rem;
      color: #212121;
      border: solid 1px var(--color-dark-variant);
  }
  .entry-four select {
      margin: 0.5em;
      gap: 1rem;
      height: 1.8rem;
      padding: 0.3rem;
      font-size: 1rem;
      color: #212121;
      border: solid 1px var(--color-dark-variant);
  }
  .save {
      width: 150px;
      height: 2rem;
      background: var(--color-dark);
      color: var(--color-white);
      display: block;
      margin: 1rem auto;
  }
  
  .red {
      background: var(--color-danger);
      color: var(--color-white);
  }
  
  .blue {
      background: var(--color-primary);
      color: var(--color-white);
  }
  
  .green {
      background: var(--color-success);
      color: var(--color-white);
  }
  
  .save:hover {
      background: var(--color-dark-variant);
  }
  
  .entry-block input {
      margin: 0.2rem;
      padding: 0.2rem;
      gap: 0.2rem;
      height: 2rem;
      color: #212121;
      width: 20rem;
      border: solid 1px var(--color-dark-variant);
  }
  
  .entry-block label {
      margin: 0.5em;
      gap: 0.3rem;
      height: 2rem;
      font-size: 1rem;
      text-align: center;
      color: var(--color-dark);
      border-bottom: solid 1px var(--color-dark-variant);
  }
  
  .entry-block p {
      margin: 0.5em;
      gap: 1rem;
      height: 1rem;
      text-align: center;
      color: #212121;
  }
  
  .entry-block select {
      margin: 0.5em;
      gap: 2rem;
      height: 2rem;
      font-size: 1rem;
      color: #212121;
      border: solid 1px var(--color-dark-variant);
  }
  
  .entry-block {
      display: grid;
      grid-template-columns: auto;
      align-items: center;
      padding: 0.5rem;
      align-items: center;
      justify-content: center;
      margin: auto;
  }
  
  .flex {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      gap: 0.5rem;
      justify-content: center;
  }
  
  
  /* ================== hover and submenu============== */
  
  .menu .submenu,.submenu{
      display: none;
  }
  
  .submenu li {
      transition: all ease 0.2s;
  }
  
  .submenu li a {
      display: flex;
      gap: 1rem;
      padding-left: 1rem;
      align-items: center;
      position: relative;
      margin-left: 1.5rem;
      color: var(--color-dark);
      height: 1.5rem;
      transition: all 300ms ease;
  }
  
  /* .menu:hover .submenu {
      display: block;
      color: var(--color-dark);
  } */
  
  
  
  .btn {
      display: flex;
      margin: auto;
      justify-content: center;
      text-align: center;
  }
  
  .btn button {
      display: inline-block;
      background: var(--color-dark);
      margin: 1rem;
      color: var(--color-info-light);
      padding: 0.4rem;
  }
  
  .btn button:hover {
      background: var(--color-dark-variant);
  }
  
  
  
  /* close side menu animation  */
  
  input#menu {
    display: none;
  }
  
  
  
  label.icon {
    position: fixed;
    z-index: 200;
  }
  
  .icon .menu,
  .icon .menu::before,
  .icon .menu::after {
    background: var(--color-dark);
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    transition: background ease .3s, top ease .3s .3s, transform ease .3s;
    width: 20px;
  }
  
  .icon:hover .menu,
  .icon:hover .menu::before,
  .icon:hover .menu::after {
    background: #47B74B;
  }
  
  .icon .menu {
    left: 18px;
    top: 27px;
  }
  
  .icon .menu::before {
    top: -6px;
  }
  
  .icon .menu::after {
    top: 6px;
  }
  
  #menu:checked + .icon .menu {
    background: transparent;
  }
  
  #menu:checked + .icon .menu::before {
    transform: rotate(45deg);
  }
  
  #menu:checked + .icon .menu::after {
    transform: rotate(-45deg);
  }
  
  #menu:checked + .icon .menu::before,
  #menu:checked + .icon .menu::after {
    top: 0;
    transition: top ease .3s, transform ease .3s .3s;
  }
  
  nav {
    background: #FFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    height: 100vh;
    padding-top: 60px;
    position: fixed;
    transition: width ease .6s;
    width: 60px;
    z-index: 100;
    
    overflow: hidden;
  }
  
  #menu:checked ~ nav {
    width: 200px;
  }
  
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  nav li {
    cursor: pointer;
    display: block;
    line-height: 60px;
    padding-left: 16px;
    white-space: nowrap;
  }
  nav i {
    color: #ffffff;
    margin-right: 24px;
    vertical-align: text-bottom;
  }
  
  
  .model-side {
    width: 25rem;
    height: 100vh;
    background: var(--color-info-light);
    box-shadow: 0 7px 9px var(--color-dark);
    position: fixed;
    top: 0;
    right: -25rem;
    z-index: 1000;
    padding: 1rem;
    transform: translateX(-20%);
    transition: right 0.5s ease-in;
  }
  
  .model-side-show {
    right: 0;
    transform: translateX(0%);
  }
  
    
    
    
  
  .model-side-closebtn{
      background-color: var(--color-dark);
      color: var(--color-info-light);
      position: absolute;
      left:-35px;
      top: 1.8rem;
      padding: 2px;
      width: 35px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius:  15px 0 0 15px;
  }
  .rotate-icon:hover{
      transform: rotate(90deg);
      transition: all 0.3s ease-in-out;
  }
  
  
  /* //loader css */
  .sweet-container{
      background: #efefeff3;
      filter: blur(2px);
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1100;
  }
  .sweet-loading{
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1200;
  }
  .fullrow{
      column-span: 4;
  }
  
  .table-control{
      height: 3rem;
      border-radius:var(--card-border-radius);
      width: 100%;
      margin: 0.5rem 0;
      padding: 0.5rem;
      background-color: var(--color-white);
      color: var(--color-dark);
      display: flex;
      gap:0.8rem;
      align-items: center;
      justify-content: space-between;
  }
  .table-control input{
      height: 2rem;
      min-width: 15rem;
      border: 1px solid var(--color-dark);
      /* border-radius: var(--card-border-radius); */
  }
  .table-sort{
      min-width: 10rem;
      height: 2rem;
      /* border-radius: var(--card-border-radius); */
      /* background: var(--color-info-dark); */
      border: var(--color-dark-variant) 1px solid;
      padding: 0.2rem;
  }
  .select-option{
      /* min-width: inherit; */
      height: 4rem;
      padding: 0.2rem;
  }
  .Imgflex{
      display: block;
      justify-content: center;
     
  }
  .imageHolder{
      width: 120px;
      height: 120px;
      border-radius: 5%;
      overflow: hidden;
      border: var(--color-white) 3px solid;
      display: flex;
      margin: auto;
      align-items: center;
      justify-content: center;
      position: relative;
      box-shadow: var(--box-shadow);
  }
  .imageHolder span{
      
      font-size: 2.3rem;
     
  }
  .overlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(86, 86, 86, 0.7);
      transform: translateY(100%);
      transition: transform ease-in-out 0.2s;
    }
    
    .imageHolder:hover .overlay {
      transform: translateY(0);
    }
    
    .overlay span {
      color: white;
      font-size: 24px;
    }
    
  
    /* ==========progress bar =============== */
    .progress-bar {
      height: 24px;
      margin: 1rem auto;
      width: 97%;
      overflow: hidden;
      padding: 2px;
      border: 1px solid var(--color-info-dark);
      border-radius: var(--card-border-radius);
    }
    
    .progress-inst {
      height: 100%;
      background-color: var(--color-base);
      border-radius: var(--card-border-radius);
      transition: width 0.5s ease-in-out;
      text-align: end;
      padding-right: 0.5rem;
    }
    
  /* //.status-toggler */
    .switch {
      position: relative;
      display: inline-block;
      width: 3rem;
      height: 1.2rem;
    }
    
    .switch input { 
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-info-dark);
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 0.8rem;
      width: 0.8rem;
      left: 0.2rem;
      bottom: 0.2rem;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    input:checked + .slider {
      background-color: var(--color-success);
    }
    
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(1.8rem);
      -ms-transform: translateX(1.8rem);
      transform: translateX(1.8rem);
    }
  
    .room_list_dashboard {
      display: flex;
      flex-wrap: wrap;
      gap: 0.2rem;
    }
  
    .room_list_dashboard .room{
      border: 1px solid var(--color-dark-variant);
      padding: 3px 9px;
      font-size: 1.2rem;
    }
  
    .room_list_dashboard .room.available{
      background-color: var(--color-success);
    }
    .room_list_dashboard .room.booked{
      background-color: var(--color-primary);
    }
    .room_list_dashboard .room.maintenance{
      background-color: var(--color-warning);
    }
  
    .info.available{
      background-color: var(--color-success);
    }
    .info.occupied{
      background-color: var(--color-primary);
      color: var(--color-background);
    }
    .info.maintenance{
      background-color: var(--color-warning);
    }
  
  
  
  
    /* not found pages css */
    .not-found-page{
      background-color: var(--color-info-light);
    }
  
    .not-found {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
      /* Customize background image or color here */
      font-family: Arial, sans-serif;
    }
    
    .not-found h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
    
    .not-found p {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    
    .not-found button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background-color: #4fa94d; /* Adjust button color */
      color: #fff;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
    }
    
    .not-found button:hover {
      background-color: #3e8e41; /* Adjust button hover color */
    }
  
    .notfoundImg{
      width: 500px;
      background-blend-mode: screen;
    }
    
  
    .color-primary {
          color: var(--color-primary); /* Primary color */
      }
  
      .color-danger {
          color: var(--color-danger); /* Danger color */
      }
  
      .color-success {
          color: var(--color-success); /* Success color */
      }
  
      .color-base {
          color: var(--color-base); /* Base color */
      }
  
      .color-warning {
          color: var(--color-warning); /* Warning color */
      }
  
      .color-white {
          color: var(--color-white); /* White color */
      }
  
      .color-info-dark {
          color: var(--color-info-dark); /* Dark info color */
      }
  
      .color-info-light {
          color: var(--color-info-light); /* Light info color */
      }
    /* Optional search bar styles (uncomment and customize) */
    /* .not-found input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 1rem;
    } */
    
  
    /* dinning bill css */
  
    
  
  
  .dinning .form-group {
      /* margin-bottom: 1rem; */
      margin: auto;
      padding: 0 2rem;
  }
  
  .dinning label {
      display: block;
      margin-bottom: 0.3rem;
      font-weight: bold;
  }
  
  .dinning input[type="text"],
  .dinning select {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
  }
  
  .dinning .menu-items {
      margin-top: 20px;
  }
  
  .dinning .menu-items-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
  }
  
  .dinning .menu-item-card {
      width: 24%;
      padding: 1rem;
      border: 0.1rem solid #ccc;
      border-radius: var(--border-radius-2);
      box-shadow: 0 2px 5px var(--color-info-light);
      background-color: var(--color-background);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
  }
  
  .dinning .quantity-controls {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;
  }
  
  .dinning .quantity-controls button {
      padding: 0.8rem;
      border: none;
      background-color: #4CAF50;
      color: white;
      border-radius: 4px;
      cursor: pointer;
  }
  
  .dinning .quantity-controls button:hover {
      background-color: #45a049;
  }
  
  .dinning .quantity-controls span {
      font-size: 16px;
      font-weight: bold;
  }
  
  .dinning .total {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
      text-align: right;
      color: #333;
  }
  
  .dinning .submit-button {
      display: block;
      width: 60%;
      padding: 10px;
      background-color: var(--color-success);    
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
  }
  
  .dinning .submit-button:hover {
      background-color: var(--color-base);
  }
  
  .total-block textarea{
      width: 100%;
      outline: var(--color-info-dark) 1px solid;
      height: 4rem ;
  }
  
  .menu-item-card.highlight {
      border: 2px solid var(--color-warning);
      /* color: var(--color-background); */
  }
  
  .bill-items-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 1rem;
      background-color: var(--color-white);
  }
  
  .bill-items-table th,
  .bill-items-table td {
      border: 1px solid var(--color-info-dark);
      padding: 1rem;
      text-align: left;
  }
  
  .bill-items-table th {
      background-color: var(--color-info-light);
      color: var(--color-dark);
      /* font-weight: bold; */
      font-size: 0.8rem;
  }
  
  .bill-items-table tr:nth-child(even) {
      background-color: var(--color-background);
  }
  
  .bill-items-table tr:hover {
      background-color: var(--color-light);
  }
  
  .bill-items-table td {
      font-size: 0.9rem;
      color: var(--color-dark-variant);
  }
  
  
  /* styles.css */
  .modal {
      position: fixed;
      top: 0rem;
      left: 0;
      margin-top: 2.5rem;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .modal-content {
      background-color: var(--color-white);
      padding: var(--padding-1);
      border-radius: var(--border-radius-2);
      width: 60%;
      max-width: 50rem;
      box-shadow: var(--box-shadow);
      max-height: 80%;
      overflow-y: auto;
      position: relative;
      border-top: 0.2rem solid var(--color-base);
  }
  
  .modal-content h2 {
      margin-top: 0;
      margin-bottom: 1.2rem;
      text-align: center;
  }
  
  .modal-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: none;
      border: none;
      font-size: 2rem;
      cursor: pointer;
  }
  .modal-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* This will make sure it takes up the full modal height */
  }
  
  .modal-content form {
      display: flex;
      flex-direction: column;
  }
  
  .modal-content form .form-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      flex-wrap: wrap;
  }
  
  .modal-content form label {
      font-weight: bold;
      margin-right: 1rem;
      min-width: 8rem;
  }
  
  .modal-content form input,
  .modal-content form select,
  .modal-content form textarea {
      padding: 0.5rem;
      border: 0.1rem solid #ccc;
      border-radius: var(--border-radius-1);
      width: 100%;
      box-sizing: border-box;
  }
  
  .modal-content form input:focus,
  .modal-content form select:focus,
  .modal-content form textarea:focus {
      outline: 0.2rem solid var(--color-primary);
  }
  
  .modal-content form textarea {
      resize: vertical;
      height: 5rem; /* Default height for the textarea */
  }
  
  .modal-content form .button-group {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  
  .modal-content form button {
      padding: 0.8rem 1.2rem;
      margin-top: 1rem;
      border: none;
      /* border-radius: var(--border-radius-1); */
      cursor: pointer;
  }
  
  .modal-content form button[type="submit"] {
      background-color: var(--color-base);
      color: var(--color-white);
  }
  
  .modal-content form button[type="button"] {
      background-color: var(--color-danger);
      color: var(--color-white);
  }
  
  .modal-content form button[type="submit"]:hover ,.modal-content form button[type="button"]:hover {
      background-color: var(--color-dark);
  }
  
  @media (min-width: 1024px) {
      .modal-content form {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1rem;
          row-gap: 1rem;
      }
  
      .modal-content form label,
      .modal-content form input,
      .modal-content form select,
      .modal-content form textarea {
          grid-column: span 1;
      }
  
      .modal-content form .form-row.full-width {
          grid-column: span 2;
      }
  
      .modal-content form .button-group {
          flex-direction: row;
          justify-content: center;
          width: 100%;
          grid-column: span 2;
      }
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
      .modal-content {
          width: 70%;
      }
  
      .modal-content form .form-row {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1rem;
          row-gap: 1rem;
      }
  
      .modal-content form label,
      .modal-content form input,
      .modal-content form select,
      .modal-content form textarea {
          grid-column: span 1;
      }
  
      .modal-content form .form-row.full-width {
          grid-column: span 2;
      }
  
      .modal-content form .button-group {
          flex-direction: row;
          justify-content: center;
          width: 100%;
          grid-column: span 2;
      }
  }
  
  @media (max-width: 767px) {
      .modal-content {
          width: 90%;
          margin-top: 8rem;
      }
  
      .modal-content form .form-row {
          flex-direction: column;
          align-items: flex-start;
      }
  
      .modal-content form label {
          margin-bottom: 0.5rem;
          margin-right: 0;
      }
  
      .modal-content form input,
      .modal-content form select,
      .modal-content form textarea {
          width: 100%;
      }
  
      .modal-content form .button-group {
          flex-direction: column;
          width: 100%;
      }
  
      .modal-content form button[type="submit"] {
          margin-bottom: 1rem;
      }
  }
  
  .table-search div button{
    background: none;
    color: var(--color-primary);
  }
  
  /***====Media Queries====***/
  
  @media screen and (max-width: 1200px) {
      .container {
          width: 95%;
          grid-template-columns: 63% 22%;
          left: 15%;
      }
      .containerfull{
          width: 85%;
          left: 14%;
      }
      aside .logo h2 {
          display: block;
      }
      aside .sidebar h3 {
          display: block;
      }
    
      aside .sidebar .active{
          height: 4rem;
      }
      aside .sidebar .active a{
          height: 2rem;
      }
      aside .sidebar a:last-child {
          position: relative;
          /* margin-top: 1.8rem; */
      }
  
      
      aside .sidebar a {
          margin-left: 1.2rem; /* Reduce left margin */
          height: 3.2rem; /* Reduce height */
      }
      
      aside .sidebar a span {
          font-size: 1.3rem; /* Reduce font size */
      }
      
      aside .sidebar .active a:before {
          left: -20px; /* Adjust position */
      }
      
      aside .sidebar a.active span {
          margin-left: calc(0.9rem - 3px); /* Adjust margin-left */
      }
      
      aside .sidebar a:hover span {
          margin-left: 0.9rem; /* Adjust margin-left on hover */
      }
  
      main .insights {
          grid-template-columns: 1fr;
          gap: 0;
      }
      main .recent-orders {
          width: 97%;
          /* position: absolute; */
          /* left: 50%; */
          /* transform: translateX(-50%); */
          margin: 2rem 0 0 0.8rem;
      }
      /* main .recent-orders table {
          width: 83vw;
      } */
      /* main table thead tr th:last-child,
      main table thead tr th:first-child {
          display: none;
      }
      main table tbody tr th:last-child,
      main table tbody tr th:first-child {
          display: none;
      } */
  
      main .insights {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
  
      .login-container form{
          width: 50%;
        }
        .login-field{
          gap:15px;
          font-size: 12px;
        }
  }
  
  
  /***====Media Queries====***/
  
  @media screen and (max-width: 768px) {
      .container {
          width: 100%;
          grid-template-columns: 1fr;
          left: 0;
          top: 0;
      }
      .containerfull{
          width: 98%;
          left: 2%;
          top: 0.5rem;
      }
      /* Default hidden state */
      .asidecontainer {
          position: fixed;
          left: 2%;
          top: 1rem;
          background: var(--color-white);
          width: 17rem;
          box-shadow: 1rem 3rem 4rem var(--color-light);
          height: 100vh;
          padding-right: var(--card-padding);
          transform: translate(-150%, 10%); /* Initially hidden */
          animation: showMenu 0.3s ease-in-out forwards;
          overflow-y: auto;
      }
  
        @keyframes showMenu {
          0% {
              transform: translate(-150%, 10%);
          }
          100% {
              transform: translate(0, 10%);
          }
      }
      
      /* Visible state */
      .asidecontainer.visible {
          transform: translate(0, 6rem);
          display: block;
      }
      
      /* Hidden state */
      .asidecontainer.hidden {
          transform: translateX(-100%);
          display: none;
      }
  
      aside .logo {
          margin-left: 1rem;
      }
      aside .logo h2 {
          display: inline;
      }
     
      aside .sidebar h3 {
          display: inline;
      }
      aside .sidebar a {
          width: 100%;
          height: 1.4rem;
          padding: 2rem 0 2rem 1rem;
      }
      aside .sidebar .submenu a {
          width: 100%;
          height: 1rem;
      }
      aside .sidebar .submenu a:hover {
          border-bottom: none;
      }
      aside .sidebar .menu:last-child a{
          color: var(--color-danger);
      } 
      aside .close {
          display: inline-block;
          cursor: pointer;
          color: #454343;
      }
      aside .close:hover {
          color: var(--color-danger);
      }
      main {
          margin-top: 8rem;
          padding: 0 1rem;
      }
      main .insights {
        grid-template-columns: repeat(2, 1fr);
      }
      main .insights>div {
          width: 100%;
      }
      main .recent-orders {
          position: relative;
          margin: 3rem 0 0 0;
          width: 100%;
      }
      main .recent-orders table {
          width: 100%;
          margin: 0;
      }
  
      .right {
          width: 94%;
          margin: 0 auto 4rem;
      }
      .right .top {
          position: fixed;
          top: 0;
          left: 0;
          align-items: center;
          padding: 0 0.8rem;
          height: 4.6rem;
          background: var(--color-white);
          width: 100%;
          margin: 0;
          z-index: 2;
          box-shadow: 0 1rem 1rem var(--color-light);
      }
      .right .top .theme-toggler {
          width: 4.4rem;
          position: absolute;
          left: 45%;
      }
      .right .profile .info {
          display: none;
      }
      .right .top button {
          display: inline-block;
          background: transparent;
          cursor: pointer;
          color: var(--color-dark);
          position: absolute;
          left: 1rem;
      }
      .right .top button span {
          font-size: 2rem;
      }
        
        .login-container form{
  
          width: 70%;
  
        }
        .login-field{
          gap:15px;
          font-size: 12px;
        }
  }
  
  
  @media screen and (max-width: 480px) {
      .container {
          width: 95%;
          grid-template-columns: auto;
          top: 0.5rem;
      }
      .containerfull{
          width: 95%;
          left: 2%;
          top: 0.5rem;
      }
      main .insights{
          grid-template-columns: repeat(1, 1fr);
      }
  
      aside .sidebar a {
          margin-left: 0.8rem; /* Reduce left margin */
          height: 2.8rem; /* Reduce height */
      }
      
      aside .sidebar a span {
          font-size: 1rem; /* Reduce font size */
      }
      
      aside .sidebar .active a:before {
          left: -16px; /* Adjust position */
      }
      
      aside .sidebar a.active span {
          margin-left: calc(0.6rem - 3px); /* Adjust margin-left */
      }
      
      aside .sidebar a:hover span {
          margin-left: 0.6rem; /* Adjust margin-left on hover */
      }
      .recent-orders table {
          width: 100%; /* Ensure the table stays within the container */
          font-size: 0.7rem; /* Adjust font size for smaller screens */
      }
  
      .recent-orders table thead {
          display: none; /* Hide table headers */
      }
  
      .recent-orders table tbody tr {
          display: block; /* Display rows as blocks */
          margin-bottom: 1rem;
          border-bottom: 2px solid var(--color-light);
      }
  
      .recent-orders table tbody tr td {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem 1rem;
          height: 2rem;
          text-align: right;
          border-bottom: 1px solid var(--color-light);
      }
  
      /* .recent-orders table tbody tr td:before {
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
          font-weight: bold;
          color: var(--color-info-dark);
      } */
  
      .recent-orders table tbody tr td:last-child {
          border-bottom: none; /* Remove border from the last cell */
      }
      .d-flex {
          flex-direction: column;
          justify-content: space-between;
          gap: 0.8rem;
          width: 100%;
      }
      .table-search input{
          width: 90%;
      }
      .table-search div button{
        background: none;
        color: var(--color-background);
      }
      .entry-four{
          grid-template-columns: 20% auto;
      }
      .dinning .menu-item-card{
          width: 45%;
      }
      .login-container form {
          width: 90%; /* Adjust form width for mobile */
          padding: 15px; /* Reduce padding for mobile */
          gap: 5px; /* Reduce gap for mobile */
        }
        main .insights>div span {
            border-radius: 2%;
            width: 25%;
            margin: auto;
            text-align: center;
            color: var(--color-white);
        }
        .login-field {
          display: flex;
          flex-direction: column; /* Change to column layout for mobile */
          gap: 10px; /* Adjust gap for mobile */
          height: auto; /* Adjust height for mobile */
          margin-top: 10px; /* Reduce margin-top for mobile */
          font-size: 13px; /* Reduce font size for mobile */
        }
      
        .login-field label {
          font-size: 1rem; /* Reduce label font size for mobile */
        }
      
        .login-field input {
          width: 95%;
          height: 2rem; /* Reduce input height for mobile */
          padding: 0.4rem; /* Adjust padding for mobile */
          font-size: 1rem; /* Reduce input font size for mobile */
        }
  
        /* Ensure no padding or margin for the last td and its child div */
      .recent-orders table tbody tr td:last-child {
          padding: 0;
          margin: 0;
      }
  
      .recent-orders table tbody tr td:last-child div {
          width: 100%;
          padding: 0 !important;
          margin: 0 !important;
          top: -7px;
          position: relative;
          height: 90%;
      }
  
      /* Define styles for the buttons */
      .recent-orders table tbody tr td div a {
          flex: 1;
          text-align: center;
          height: 100%;
          padding: 0.5rem;
          box-sizing: border-box;
      }
  
      /* Remove margin for the first and last anchor tags to avoid extra spacing on the edges */
      .recent-orders table tbody tr td div a:first-child {
          margin-left: 0;
      }
  
      .recent-orders table tbody tr td div a:last-child {
          margin-right: 0;
      }
  
      /* Optional: Styling for buttons */
      .primary {
          background-color: var(--color-primary);
          color: white;
          text-decoration: none;
      }
  
      .warning {
          background-color: var(--color-danger);
          color: white;
          text-decoration: none;
      }
  
      /* Optional: Hover effect for buttons */
      .primary:hover, .warning:hover {
          opacity: 0.8;
      }
  
  
  }
  